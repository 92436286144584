import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Text,
  VStack,
  Container,
  SkeletonCircle,
  SkeletonText,
  Flex,
  ScaleFade,
} from '@chakra-ui/react';
import SummonerCard from '../components/SummonerDetails/SummonerCard';
import MatchHistoryCard from '../components/SummonerDetails/MatchHistoryCard';
import {
  FaChevronUp,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import image from '../imgs/bg2.jpg';
import GlitchBackground from '../components/GlitchBackground';
import PerformanceGraph from '../components/SummonerDetails/PerformanceGraph';

export const SummonerDetails = () => {
  const { summonerName } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedMatches, setExpandedMatches] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isGraphExpanded, setIsGraphExpanded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/summoner/${summonerName}`)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setIsLoading(false);
      });
  }, [summonerName]);

  const handleShowMore = matchId => {
    if (expandedMatches.includes(matchId)) {
      setExpandedMatches(expandedMatches.filter(id => id !== matchId));
      setIsExpanded(false);
    } else {
      setExpandedMatches([...expandedMatches, matchId]);
      setIsExpanded(true);
    }
  };
  const handleGraphExpand = () => {
    setIsGraphExpanded(!isGraphExpanded);
  };

  if (isLoading) {
    return (
      <Container height="100vh" centerContent>
        <VStack>
          <SkeletonCircle size="150px" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        </VStack>
      </Container>
    );
  }

  const rankedSoloData =
    data.summonerRankedData.find(
      rankedData => rankedData.queueType === 'RANKED_SOLO_5x5'
    ) || {};
  const rankedFlexData =
    data.summonerRankedData.find(
      rankedData => rankedData.queueType === 'RANKED_FLEX_SR'
    ) || {};
  const rankedArenaData =
    data.summonerRankedData.find(
      rankedData => rankedData.queueType === 'CHERRY'
    ) || {};

  console.log('RANKED DATA: ', data.summonerRankedData);

  return (
    <>
      <GlitchBackground imageUrl={image} />
      <Container maxW="container.xl" paddingY={10}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
          align={{ base: 'center', lg: 'flex-start' }}
          height="100%"
        >
          {isLoading ? (
            <VStack>
              <SkeletonCircle size="150px" />
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
            </VStack>
          ) : (
            <>
              {!isGraphExpanded && data && data.summonerData && (
                <ScaleFade initialScale={0.9} in={!isExpanded}>
                  <Box w={'full'} mb={{ base: 5, lg: 0 }}>
                    <SummonerCard
                      summonerData={data.summonerData}
                      rankedSoloData={rankedSoloData || {}}
                      rankedFlexData={rankedFlexData || {}}
                      rankedArenaData={rankedArenaData || {}}
                    />
                  </Box>
                </ScaleFade>
              )}
              {!isGraphExpanded && data && data.matchData && (
                <ScaleFade initialScale={0.9} in={true}>
                  <Box
                    flex={isExpanded ? '1' : 'none'}
                    w={'full'}
                    mb={{ base: 5, lg: 0 }}
                  >
                    <Box as="header" mb={5}>
                      <Text fontSize="2xl" fontWeight="bold" paddingLeft={3}>
                        Match History
                      </Text>
                    </Box>
                    <Box overflowY={'auto'} maxH={'80vh'}>
                      {Object.values(data.matchData).map((match, index) => (
                        <MatchHistoryCard
                          key={index}
                          matchData={match}
                          summonerData={data.summonerData}
                          expandedMatches={expandedMatches}
                          handleShowMore={() =>
                            handleShowMore(match.metadata.matchId)
                          }
                          isExpanded={isExpanded}
                          setIsExpanded={setIsExpanded}
                          ChevronIcon={isExpanded ? FaChevronUp : FaChevronDown}
                        />
                      ))}
                    </Box>
                  </Box>
                </ScaleFade>
              )}
              {data && data.matchData && !isExpanded && (
                <Box
                  w={isGraphExpanded ? '100vw' : ['80%', '20%']}
                  mb={{ base: 5, lg: 0 }}
                  p={isGraphExpanded ? 5 : 2}
                  borderRadius={20}
                  backgroundColor={'black'}
                >
                  <Box as="header" mb={5} mt={1} d="flex" alignItems="center">
                    <Text fontSize="2xl" fontWeight="bold">
                      Performance Graph
                    </Text>
                    <Box ml="auto" cursor="pointer" onClick={handleGraphExpand}>
                      {isGraphExpanded ? <FaChevronRight /> : <FaChevronLeft />}
                    </Box>
                  </Box>
                  {isGraphExpanded && (
                    <ScaleFade initialScale={0.9} in={isGraphExpanded}>
                      <Box overflowY={'auto'} maxH={'80vh'}>
                        <PerformanceGraph
                          matchData={Object.values(data.matchData)}
                          summonerData={data.summonerData}
                          isGraphExpanded={isGraphExpanded}
                        />
                      </Box>
                    </ScaleFade>
                  )}
                </Box>
              )}
            </>
          )}
        </Flex>
      </Container>
    </>
  );
};

export default SummonerDetails;

// GlitchBackground.js
import React, { useEffect, useRef } from 'react';
import '../styles/GlitchBackground.css';
import { Box } from '@chakra-ui/react';

const GlitchBackground = ({ imageUrl, children }) => {
  const glitchRef = useRef(null);

  useEffect(() => {
    let timeoutId;

    const interval = setInterval(() => {
      glitchRef.current.classList.add('animate');
      timeoutId = setTimeout(
        () => glitchRef.current.classList.remove('animate'),
        1000
      );
    }, 1500);

    return () => {
      clearInterval(interval);
      clearTimeout(timeoutId); // this line added
    }; // cleanup on unmount
  }, []);

  return (
    <Box
      className="GlitchBackground"
      style={{ backgroundImage: `url(${imageUrl})` }}
      ref={glitchRef}
    >
      <Box className="GlitchContent">{children}</Box>
    </Box>
  );
};

export default GlitchBackground;

import React from 'react';
import {
  Box,
  Text,
  VStack,
  Button,
  Image,
  HStack,
  useColorModeValue,
  Grid,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);
const MotionGrid = motion(Grid);

function MatchHistoryCard({
  matchData,
  summonerData,
  expandedMatches,
  handleShowMore,
  ChevronIcon,
  setIsExpanded,
  isExpanded,
}) {
  const { metadata, info } = matchData;
  const summonerParticipant = info.participants.find(
    participant => participant.puuid === summonerData.puuid
  );

  const participants = info.participants;
  const half = Math.ceil(participants.length / 2);
  const firstHalfParticipants = participants.slice(0, half);
  const secondHalfParticipants = participants.slice(half);

  const boxSize = useBreakpointValue(['50px', '70px']);
  const widthValue = useBreakpointValue(['100%', '30vw']);

  return (
    <MotionBox
      initial={{ width: '100%' }}
      animate={{ width: isExpanded ? 'max-content' : widthValue }} // Width will be 100% on smaller screens and 30vw on larger screens
      transition={{ duration: 0.4, ease: 'easeInOut' }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      m={3}
      bg={useColorModeValue('gray.200', 'gray.900')}
      fontFamily="'Roboto', sans-serif"
      maxWidth="100vw" // Prevent horizontal scrolling on mobile
    >
      <VStack p="4" spacing="6">
        <HStack spacing="4" wrap="wrap">
          {' '}
          {/* Wrap elements on small screens */}
          <Image
            boxSize={boxSize} // Smaller image on mobile
            src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${summonerParticipant.championName}.png`}
            alt=""
          />
          <VStack align="start">
            <Text fontSize="lg" fontWeight="bold">
              {summonerParticipant.championName}
            </Text>
            <Text>Level: {summonerParticipant.champLevel}</Text>
          </VStack>
          <HStack spacing="2">
            <Text>Kills: {summonerParticipant.kills}</Text>
            <Text>Deaths: {summonerParticipant.deaths}</Text>
            <Text>Assists: {summonerParticipant.assists}</Text>
          </HStack>
        </HStack>

        <IconButton
          size="sm"
          onClick={() => {
            handleShowMore(metadata.matchId);
            setIsExpanded(!isExpanded);
          }}
          mt="4"
          icon={<ChevronIcon boxSize={4} />}
        />

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.4 }}
            >
              <MotionGrid
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                gap={4}
              >
                {firstHalfParticipants.map((participant, index) => (
                  <HStack
                    key={index}
                    p="2"
                    spacing="4"
                    align="start"
                    bgColor="white"
                    borderRadius="md"
                    color="black"
                    wrap="wrap" // Wrap elements on small screens
                  >
                    <Image
                      boxSize={boxSize} // Smaller image on mobile
                      src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${participant.championName}.png`}
                      alt=""
                    />
                    <VStack align="start">
                      <Text>Champion: {participant.championName}</Text>
                      <Text>Level: {participant.level}</Text>
                    </VStack>
                    <HStack spacing="2">
                      <Text>Kills: {participant.kills}</Text>
                      <Text>Deaths: {participant.deaths}</Text>
                      <Text>Assists: {participant.assists}</Text>
                    </HStack>
                  </HStack>
                ))}
                {secondHalfParticipants.map((participant, index) => (
                  <HStack
                    key={index}
                    p="2"
                    spacing="4"
                    align="start"
                    bgColor="white"
                    borderRadius="md"
                    color="black"
                    wrap="wrap" // Wrap elements on small screens
                  >
                    <Image
                      boxSize={boxSize} // Smaller image on mobile
                      src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${participant.championName}.png`}
                      alt=""
                    />
                    <VStack align="start">
                      <Text>Champion: {participant.championName}</Text>
                      <Text>Level: {participant.level}</Text>
                    </VStack>
                    <HStack spacing="2">
                      <Text>Kills: {participant.kills}</Text>
                      <Text>Deaths: {participant.deaths}</Text>
                      <Text>Assists: {participant.assists}</Text>
                    </HStack>
                  </HStack>
                ))}
              </MotionGrid>
            </motion.div>
          )}
        </AnimatePresence>
      </VStack>
    </MotionBox>
  );
}

export default MatchHistoryCard;

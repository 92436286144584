import React, { useEffect } from 'react';
import image from '../imgs/bg.jpg';
import GlitchBackground from '../components/GlitchBackground';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Title from '../components/Title';
import SubTitle from '../components/SubTitle';
import UserInput from '../components/UserInput';
import { Center, Stack, VStack } from '@chakra-ui/react';
import Logo from '../components/logo';

const Index = () => {
  return (
    <>
      <GlitchBackground imageUrl={image} />
      <Logo /> {/* use Logo component */}
      <Center h="100vh" mt={'-10vh'}>
        <VStack align="center">
          <Title />
          <Stack spacing={10}>
            <SubTitle />
            <UserInput />
          </Stack>
        </VStack>
      </Center>
    </>
  );
};

export default Index;

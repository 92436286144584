import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import logoImage from '../imgs/logo.png';
import '../styles/Logo.css';

const Logo = () => (
  <Box position="absolute" top={100} left={5}>
    <Image src={logoImage} alt="logo" draggable={'false'} className="icon" />
  </Box>
);

export default Logo;

// Title.js
import { Text } from '@chakra-ui/react';
import { colors } from '../constants/colors';

const Title = () => (
  <Text
    fontSize={['4xl', '5xl', '6xl', '7xl', '8xl']}
    color={colors.white}
    fontFamily={'"Bebas Neue", cursive'}
  >
    UNLEASH THE BEAST WITHIN
  </Text>
);

export default Title;

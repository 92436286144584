import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Index from './pages';
import { SummonerDetails } from './pages/SummonerDetails';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/summoner/:summonerName"
          element={
            <ChakraProvider>
              <SummonerDetails />{' '}
            </ChakraProvider>
          }
        />
        <Route
          path="/"
          element={
            <ChakraProvider>
              <>
                <Index />{' '}
              </>
            </ChakraProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

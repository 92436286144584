// SubTitle.js
import { Box, Text } from '@chakra-ui/react';
import { colors } from '../constants/colors';

const SubTitle = () => (
  <Box
    backgroundColor={colors.cyan}
    p={3}
    width="max-content"
    textAlign="center"
    boxShadow="lg"
  >
    <Text
      fontSize={['2xl', '3xl', '4xl']}
      color={colors.text}
      fontFamily={'"Bebas Neue", cursive'}
    >
      Enter your summoner name below to begin
    </Text>
  </Box>
);

export default SubTitle;

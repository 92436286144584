// UserInput.js
import React, { useState } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom'; // Updated this line
import { colors } from '../constants/colors';

const UserInput = () => {
  const [summonerName, setSummonerName] = useState('');
  const navigate = useNavigate(); // Updated this line

  const handleButtonClick = () => {
    navigate(`/summoner/${summonerName}`);
  };

  return (
    <InputGroup size="lg">
      <InputLeftElement
        pointerEvents="none"
        children={<AiOutlineUser color="black" />}
      />
      <Input
        bg="white"
        placeholder="Summoner Name..."
        _placeholder={{ color: colors.text }}
        borderRadius="full"
        textColor={colors.text}
        value={summonerName}
        onChange={e => setSummonerName(e.target.value)}
      />
      <InputRightElement
        children={
          <Button
            size="sm"
            borderRadius="full"
            colorScheme="red"
            backgroundColor={colors.red}
            p={5}
            mx={5}
            onClick={handleButtonClick}
          >
            .gg
          </Button>
        }
      />
    </InputGroup>
  );
};

export default UserInput;

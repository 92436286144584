export const RANK_IMAGES = {
  IRON: 'https://lh3.googleusercontent.com/WUWV7aDtQAHnGzAw4XPBe3hrx_vfytHU4SVTZzdS__29Evg33dI25KZJOqgg7OoXV7Ve62m9rkpo-x1rFTo5E8dnEildJ7mp5z8VM7ZCfq2NNT7UvYyhmiZUJFhjsyFgtW2JLJo6Rw=w2400',
  BRONZE:
    'https://lh3.googleusercontent.com/sE3cWKDOIpTF26AfHsi6KRnZn1bkBY0-pXI-3vc-7geQ95W918j6LICKXo-9by4E8va-ZpJ99u2ltMMvEDF32_gno31qJuudePp8CZYkQFlxLi-YFcvG3OjzCKHi3SOfTq-JeVSqZg=w2400',
  SILVER:
    'https://lh3.googleusercontent.com/-nCGX7CJBFEg-jWr5Hi4MmJq3Z2MWLm7RRNkOIfUgo2zbnta9vFVmUsXoNFr4eqVyHiY4l2hrRSThEIqRjaVa1RcuZKgfyVu_anzZe6A1gPu0ewBMZf75WlNp3yaLOEK1EZi7HVVSA=w2400',
  GOLD: 'https://lh3.googleusercontent.com/nOJ7XRdqtbZaiVJQDDoB4_zivrK5JNlE2ggGXBE7_1x-3OpHxx483y_SgLiofgrw4LsWp8MfWK3-09WejMSKEbKnx3ssDSiuFWqpKaNAi23KUG4gt23QzEjYPBLAHy5ooQA9t7Ls_Q=w2400',
  PLATINUM:
    'https://lh3.googleusercontent.com/v2vZH7gJiJnd7l-muGcciB-VVmeSW7mkqSsett4ekLiIdf1T84B2VG-Cg6iE9RcW-YJ-2Mh8ITtcegXFokj00F5WDXJD64gKu702nqBylUFDNkrNLuMuoAx6jclp4GjUubOkeOPKEg=w2400',
  DIAMOND:
    'https://lh3.googleusercontent.com/DuwXw7OvSMg-SQBuaC8p46iRqMQt5_DkUcZ2hvu4WTT-M2CNHbWVtn_3MLq89pSDJMq1-NNSHKBlA3NBJeVvlrfbvxxtRU6_SY9Me9y10gIHniicqGxqcFbUX-_0-oAU3ZlAujKNDw=w2400',
  MASTER:
    'https://lh3.googleusercontent.com/3h6T_WzUqZyrMwjQ1zZZ08VUnVeQgPeUWB4pV7pbY2ispGVVqdfOPUuOgwICoI-5a1AlQUMlZxAmhRIMjpo1vQLkANmYmZ6jw2aPkrESyzKujtXUssFrkmzTkaiBqHUxJi0I4lMuhQ=w2400',
  GRANDMASTER:
    'https://lh3.googleusercontent.com/mvESRBU91R9lBF_pPjotVt4IW00UT2bnpzZ2a4cRxcXq8Qg0unF_H8594zbtHQaWT4SfqSDWQOxrJsmNehG553wi0GnqRELWSSwbySfH5nF1LU335Xh9QsiaZaurCqalZs4-lAv-Hg=w2400',
  CHALLENGER:
    'https://lh3.googleusercontent.com/-0Cda9b8O1KtYaSGyKi_uwumt77SrKgEcj5Dys8uu6YOpAwA39sGCP66fE8qw28jAN-DiEzSmIkYDQssdphWTQ0dr8b8lhCnzx0qewQZWphp7EE7HJWgQt_5x3-2RXoLWWStCUsBcg=w2400',
  UNRANKED:
    'https://static.wikia.nocookie.net/leagueoflegends/images/3/38/Season_2019_-_Unranked.png/',
};

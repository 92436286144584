import {
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { RANK_IMAGES } from '../../constants/ranks';
import bg from '../../imgs/bg.jpg';

const calculateWinRate = (win, loss) => {
  const total = parseInt(win) + parseInt(loss);
  return total !== 0 ? Math.round((parseInt(win) / total) * 100) : 0;
};

const RankedQueue = ({ queueType, queueData }) => {
  const {
    tier = 'UNRANKED',
    rank = '',
    leaguePoints = 0,
    wins = 0,
    losses = 0,
  } = queueData;
  console.log('QUEUE DATA: ', queueData);
  const fontColor = '#15172A';
  const winRate = calculateWinRate(wins, losses);

  return (
    <VStack textAlign="center" spacing={2}>
      <Text
        fontFamily={'body'}
        fontSize={'2xl'}
        fontWeight={400}
        color={fontColor}
      >
        {queueType}
      </Text>
      <Image
        src={RANK_IMAGES[tier] || 'https://loremflickr.com/g/320/240/paris'}
        objectFit="cover"
        width={'6em'}
        height={'7em'}
      />
      <Text fontSize={15} color={fontColor}>
        {tier} {rank}
      </Text>
      <Text fontSize={15} color={fontColor}>
        W: {wins} | L: {losses}
      </Text>
      <Text fontSize={15} color={fontColor}>
        {leaguePoints} LP
      </Text>
      <Text fontSize={15} color={fontColor}>
        Win Rate: {winRate}%
      </Text>
    </VStack>
  );
};

const SummonerCard = props => {
  const { summonerData, rankedSoloData, rankedFlexData, rankedArenaData } =
    props;

  const fontColor = '#15172A';
  const fontSize = useBreakpointValue({ base: 'md', md: 'lg' });
  console.log(summonerData);
  console.log(JSON.stringify(rankedFlexData));
  console.log(JSON.stringify(rankedSoloData));
  if (rankedFlexData === {}) {
    console.log('rankedFlexData is empty');
  }
  if (rankedSoloData === {}) {
    console.log('rankedSoloData is empty');
  }

  return (
    <Box h="full">
      <Box
        w="full"
        h="full"
        bg="white"
        boxShadow={'2xl'}
        rounded={'lg'}
        overflow={'hidden'}
      >
        <Image h="200px" w="full" src={bg} objectFit={'cover'} />
        <Flex justify={'center'} mt={-20}>
          <Avatar
            boxSize={'8em'}
            src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/profileicon/${summonerData.profileIconId}.png`}
            css={{
              border: '2px solid white',
            }}
          />
        </Flex>
        <Center h="full" w="full" p={4}>
          <Box>
            <Stack spacing={0} align={'center'} mb={5}>
              <Text
                fontSize={fontSize}
                fontWeight={500}
                fontFamily={'body'}
                color={fontColor}
              >
                {summonerData.name}
              </Text>
              <Text color={'gray.500'} fontSize={'2xl'}>
                lvl {summonerData.summonerLevel}
              </Text>
            </Stack>
            <HStack spacing={6} w="full" justifyContent="space-between">
              <RankedQueue queueType="Ranked Solo" queueData={rankedSoloData} />
              <RankedQueue queueType="Ranked Flex" queueData={rankedFlexData} />
              <RankedQueue
                queueType="Ranked Arena"
                queueData={rankedArenaData}
              />
            </HStack>
          </Box>
        </Center>
      </Box>
    </Box>
  );
};

export default SummonerCard;

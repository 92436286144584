// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  padding: 5px 10px;
}
.icon:hover {
  transform: rotate(360deg);
  transition: all 0.5s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Logo.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,gCAAgC;AAClC","sourcesContent":[".icon {\n  padding: 5px 10px;\n}\n.icon:hover {\n  transform: rotate(360deg);\n  transition: all 0.5s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

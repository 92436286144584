import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { motion } from 'framer-motion';
import { Stack } from '@chakra-ui/react';

const theme = {
  tooltip: {
    container: {
      background: '#333', // dark background
      color: '#fff', // white text
    },
  },
};

const PerformanceGraph = ({ matchData, summonerData, isGraphExpanded }) => {
  // Extract the data from the last 10 matches
  const lastTenMatches = matchData.slice(-10);

  const damageDealtData = prepareData(
    lastTenMatches,
    'totalDamageDealtToChampions',
    'Total Damage Dealt To Champions',
    summonerData.puuid
  );
  const visionWardsData = prepareData(
    lastTenMatches,
    'wardsPlaced',
    'Wards Placed',
    summonerData.puuid
  );
  const minionsKilledData = prepareData(
    lastTenMatches,
    'totalMinionsKilled',
    'Total Minions Killed',
    summonerData.puuid
  );
  const visionScoreData = prepareData(
    lastTenMatches,
    'visionScore',
    'Vision Score',
    summonerData.puuid
  );

  const variants = {
    hidden: { opacity: 0, x: 50 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: { opacity: 0, x: -50 },
  };

  const MOBILE_THRESHOLD = 800;

  // Calculate the height based on device width
  const deviceHeight = window.innerWidth > MOBILE_THRESHOLD ? '500px' : '300px';

  return (
    <Stack width={['100vw', '80vw']} height={'80vh'}>
      <motion.div
        style={{ height: deviceHeight }}
        initial="hidden"
        animate={isGraphExpanded ? 'show' : 'exit'}
        variants={variants}
      >
        <Graph
          data={damageDealtData}
          title="Total Damage Dealt To Champions"
          line
        />
        <Graph data={visionWardsData} title="Wards Placed In Game" />
        <Graph data={minionsKilledData} title="Total Minions Killed" />
        <Graph data={visionScoreData} title="Vision Score" line />
      </motion.div>
    </Stack>
  );
};

const prepareData = (data, key, title, puuid) => ({
  keys: [title],
  data: data.map((item, i) => {
    // Find the participant associated with the puuid
    const participant = item.info.participants.find(p => p.puuid === puuid);
    return {
      game: `Game ${i + 1}`,
      [title]: participant ? participant[key] : 0, // Use the key to get the right stat from the participant
    };
  }),
});

const Graph = ({ data, title, line = false }) =>
  line ? (
    <ResponsiveLineGraph data={data} title={title} />
  ) : (
    <ResponsiveBarGraph data={data} title={title} />
  );

const ResponsiveBarGraph = ({ data, title }) => (
  <>
    <h2>{title}</h2>
    <ResponsiveBar
      data={data.data}
      keys={data.keys}
      indexBy="game"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      colors={{ scheme: 'nivo' }}
      axisTop={null}
      axisRight={null}
      theme={theme}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Game',
        legendPosition: 'middle',
        legendOffset: 32,
        tickValues: 'every 1', // this will show ticks for every game
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: title,
        legendPosition: 'middle',
        legendOffset: -40,
        format: '.2s', // this will format the ticks, if necessary
      }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  </>
);

const ResponsiveLineGraph = ({ data, title }) => (
  <>
    <h2>{title}</h2>
    <ResponsiveLine
      data={[
        {
          id: title,
          data: data.data.map(item => ({
            x: item.game,
            y: item[title],
          })),
        },
      ]}
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Game',
        legendPosition: 'middle',
        legendOffset: 32,
        tickValues: 'every 1', // this will show ticks for every game
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: title,
        legendPosition: 'middle',
        legendOffset: -40,
        format: '.2s', // this will format the ticks, if necessary
      }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  </>
);

export default PerformanceGraph;
